import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    this.initTooltips();
  }

  initTooltips() {
    $("body").tooltip({
      selector: "[data-toggle='tooltip']",
      container: "body"
    });
  }
}
