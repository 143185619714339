import { Controller } from "@hotwired/stimulus";
import $ from "jquery"; // required by jquery-backstretch
import "jquery-backstretch";
import modernizr from "modernizr";

export default class extends Controller {
  static targets = ["header"];
  static values = {
    jpgs: Array,
    webps: Array
  };

  connect() {
    const images = modernizr.webp ? this.webpsValue : this.jpgsValue;

    $(this.headerTarget).backstretch(images, {
      duration: 3000,
      fade: 1200
    });
  }
}
