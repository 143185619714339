// Entry point for the build script in your package.json

/* eslint-disable import/first */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
import "@hotwired/turbo-rails";
import "./controllers/index.js"; // Stimulus

import "trix";
import "@rails/actiontext";

import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

import "./dependencies.js.erb";

// ------------------------------------------------------------------------------
// Our own javascript
// ------------------------------------------------------------------------------
import "./global.js";

// Components
import "./components/_navs.js";
/* eslint-enable import/first */
