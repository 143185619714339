import $ from "jquery";

$(document).on("click", "div[data-href]", function(ev) {
  if (!$(ev.target).is("a, a i, a img, input, button, .btn")) {
    window.location.href = $(this).data("href");
  }
});

$(document).on("click", "tr[data-href] td:not(.no-follow)", function(ev) {
  const href = $(this).parent().data("href");
  if (href != null) {
    window.location.href = href;
  }
});

$(document).on("click", "a.disabled", function(ev) {
  return ev.preventDefault();
});
