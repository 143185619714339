import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["address"];
  static values = {
    travel: Boolean
  };

  connect() {
    this.displayTravelFields(this.travelValue);
  }

  toggleTravelDetails(event) {
    this.displayTravelFields(event.target.value === "true");
  }

  displayTravelFields(displayed) {
    if (displayed) {
      this.addressTarget.classList.remove("hidden");
    } else {
      this.addressTarget.classList.add("hidden");
    }
  }
}
