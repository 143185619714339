import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];
  static values = {
    attribute: { type: String, default: "height" },
    offset: { type: Number, default: 0 }
  };

  connect() {
    this.setHeight();
  }

  setHeight() {
    // TODO: Call the attributeValue method dynamically
    const value = `${(window.innerHeight + this.offsetValue)}px`;
    if (this.attributeValue === "height") {
      this.elementTarget.style.height = value;
    } else if (this.attributeValue === "min-height") {
      this.elementTarget.style.minHeight = value;
    }
  }
}
