/* eslint-disable no-undef */
// Wrapper for `turbolinks:load` to support IE9.
// TODO: action once https://github.com/turbolinks/turbolinks/pull/166 is merged.
if (Turbo.supported) {
  $(document).on("turbo:load", function() {
    return $(this).trigger("page:show");
  });
} else {
  $(document).ready(function() {
    return $(this).trigger("page:show");
  });
}
