import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["header", "navbar"];

  connect() {
    this.element.setAttribute("data-action", "resize@window->clients--hollykathleen#dynamicElements scroll@window->clients--hollykathleen#dynamicElements");
    this.enableSpyScroll();
    this.expandableComments();
    this.dynamicElements();
  }

  dynamicElements() {
    if (this.hasNavbarTarget) {
      this.toggleNavbar();
      this.scrollFade();
    }
  }

  // private

  // Use to update the nav items on scroll
  enableSpyScroll() {
    $(this.element).scrollspy({ target: ".navbar", offset: 72 });
  }

  expandableComments() {
    $(".comments .comment").on("click", function(ev) {
      if (!$(ev.target).is(".attachments, .attachment img")) {
        return $(this).find(".body").each(function() {
          if ($(this).hasClass("collapsed")) {
            return $(this).removeClass("collapsed");
          }
        });
      }
    });
    $("#comments .new-comment").on("click", function(ev) {
      return $(this).addClass("hidden");
    });
  }

  toggleNavbar() {
    if ($(window).scrollTop() < $(window).height() - 50) {
      this.navbarTarget.classList.remove("scrolled");
      this.headerTarget.classList.remove("invisible");
    } else {
      this.navbarTarget.classList.add("scrolled");
      this.headerTarget.classList.add("invisible");
    }
  }

  scrollFade() {
    const st = $(window).scrollTop();
    const wh = $(window).height();
    const sf = 1.1 - (st / (10 * wh));
    this.headerTarget.querySelector(".container").style.opacity = 1.4 - (st / 150);

    const backstretchImages = this.headerTarget.querySelector(".backstretch img");
    if (backstretchImages) {
      this.headerTarget.querySelector(".backstretch img").style.transform = `scale(${sf})`;
    }
  }
}
