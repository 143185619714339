/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import "waypoints/lib/jquery.waypoints.js";

export default class extends Controller {
  static targets = ["scrollimation"];

  connect() {
    this.scrollimation();
  }

  scrollimation() {
    this.scrollimationTargets.forEach(target => {
      // eslint-disable-next-line no-new
      new Waypoint({
        element: target,
        handler: direction => {
          $(target).addClass("in");
        },
        offset: "60%"
      });
    });
  }
}
