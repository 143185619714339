import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["dateInput", "timeInput"];

  dateInputTargetConnected(element) {
    flatpickr(element, {
      altInput: true,
      dateFormat: "Y-m-d",
      altFormat: "D j M Y"
    });
  }

  timeInputTargetConnected(element) {
    flatpickr(element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      time_24hr: true,
      defaultDate: element.value
    });
  }

  dateInputTargetDisconnected(element) {
    flatpickr(element).destroy();
  }

  timeInputTargetDisconnected(element) {
    flatpickr(element).destroy();
  }
}
