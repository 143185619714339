import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["editor"];

  insertLink(event) {
    const url = event.currentTarget.getAttribute("data-url");
    const label = event.currentTarget.getAttribute("data-label");

    const htmlContent = `<strong><a href="${url}">${label}</a></strong>`;
    this.editorTarget.editor.insertHTML(htmlContent);
  }
}
