import { Controller } from "@hotwired/stimulus";
import $ from "jquery"; // required by jquery-scrollto
import "jquery.scrollto/jquery.scrollTo.js";

export default class extends Controller {
  static targets = ["mainNavItem", "sideNavItem"];
  static values = {
    offset: { type: Number, default: 0 },
    section: String
  };

  connect() {
    this.mainNavItemTargets.forEach(item => {
      item.setAttribute("href", item.getAttribute("scroll-to-href"));
    });

    if (this.hasSectionValue) {
      document.querySelector(`#navbar a[href='${this.sectionValue}']`).click();
    }
  }

  scroll(event) {
    event.preventDefault();
    $(window).stop(true).scrollTo(event.currentTarget.hash, {
      duration: 2000,
      interrupt: false,
      offset: {
        top: this.offsetValue
      }
    });

    // TODO: check on mobile
    const collapsibleNavbar = $("#navbar.navbar-collapse");
    if (collapsibleNavbar.hasClass("in")) {
      collapsibleNavbar.collapse("hide");
    }
  }
}
