import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = ["header", "navbar"];

  connect() {
    this.element.setAttribute("data-action", "resize@window->clients--oysteryacht#dynamicElements scroll@window->clients--oysteryacht#dynamicElements");
    this.dynamicElements();
  }

  dynamicElements() {
    this.scrollFade();
  }

  // private

  scrollFade() {
    const st = $(window).scrollTop();
    this.headerTarget.querySelector(".container").style.opacity = 1.2 - (st / 150);
  }
}
