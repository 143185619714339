import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  static values = {
    scrollTo: String
  };

  connect() {
    this.scrollTo(this.offset());
  }

  resetToTop() {
    window.scrollTo(0, 0);
  }

  // private

  scrollTo(offset) {
    $("html, body").animate({
      scrollTop: offset
    }, "fast");
  }

  offset() {
    const element = $(`[datetime=${this.scrollToValue}]`);
    if (element.length) {
      return element.offset().top - this.navigationHeight();
    }
  }

  navigationHeight() {
    return $(".navbar-fixed-top").height() + $(".calendar-container .navigation").height() + 100; // + 100px margin
  }
}
