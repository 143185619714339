/* eslint-disable no-undef */
import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["output"];
  static values = {
    url: String,
    trigger: { type: String, default: "on-load" }
  };

  connect() {
    if (this.triggerValue === "scroll") {
      const controller = this;
      // eslint-disable-next-line no-new
      new Waypoint({
        element: this.element,
        handler: direction => {
          controller.loadContent();
        },
        // Start loading when the content is 20% lower than the bottom of the screen
        offset: "120%"
      });
    } else if (this.triggerValue === "on-load") {
      this.loadContent();
    }
  }

  // private
  async loadContent() {
    const target = (this.hasOutputTarget ? this.outputTarget : this.element);
    if (!target.getAttribute("data-loaded")) {
      const response = await get(this.urlValue);
      if (response.ok) {
        target.innerHTML = await response.html;

        target.dispatchEvent(new CustomEvent("content:loaded", { bubbles: true }));
      }
      target.setAttribute("data-loaded", true);
    }
  }
}
