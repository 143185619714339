import { Controller } from "@hotwired/stimulus";
import GLightbox from "glightbox/dist/js/glightbox.js";

export default class extends Controller {
  static values = {
    selector: { type: String, default: "a.preview" }
  };

  connect() {
    GLightbox({ selector: this.selectorValue });
  }
}
